import QueryUtils from "../queryUtils";

const queries = [
  // Read
  `query GetLieu( $uuid: String!, $congregation: String!, $cursor: String ) {
    lieus( after: $cursor, activer: true, congregation_uuid: $congregation ) {
      edges {
        cursor

        node {
          nom
          ville
          hour
          minute
          shift
          activer
          uuid

          disponibilites( first: 100, proclamateur: $uuid, fixe: false ) {
            edges {
              node {
                day
                hour
                uuid
              }
            }
          }

          plannings( first: 100 ) {
            edges {
              node {
                day
                hour
              }
            }
          }
        }
      }
    }
  }`,
  // Update
  ``,
  // Delete
  ``,
];

export default new QueryUtils(queries);
