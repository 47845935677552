export default class CreneauUtils {
  constructor(hour, minute, shift) {
    let _hour = 2;
    let _minute = 0;
    let _shift = 0;

    if (hour > 0 && hour < 12) {
      _hour = hour;
    }

    if (minute > 0 && minute < 60) {
      _minute = minute;
    }

    if (shift > 0 && shift <= 120) {
      _shift = shift;
    }

    let _cent = _minute / 60;
    let _centShift = _shift / 60;
    this._hourMax = Number(
      (20 + _centShift - (6 + _centShift)) / (_hour + _cent)
    ).toFixed(0);
  }

  get getHourMax() {
    return this._hourMax;
  }
}
