<template>
  <div class="hour">
    <input
      class="styled-checkbox"
      type="checkbox"
      v-bind:id="disponibilite.uuid"
      v-bind:disabled="!disponibilite.isEnabled"
      v-model="disponibilite.isChecked"
    />
    <label :for="disponibilite.uuid"></label>
  </div>
</template>

<script>
export default {
  name: "DisponibiliteCheckboxItem",

  data() {
    return {
      disponibilite: {
        uuid: this.item.uuid,
        isEnabled: this.item.isEnabled,
        isChecked: this.item.isChecked,
        day: this.item.day,
        hour: this.item.hour,
      },
    };
  },

  props: {
    item: Object,
  },

  watch: {
    "disponibilite.isChecked": function () {
      this.$emit("onChange", this.disponibilite);
    },
  },
};
</script>
