<template>
  <div class="panel-group disponibilite">
    <div class="panel panel-default">
      <div class="panel-heading">
        <a data-toggle="collapse" v-bind:href="getCollapse(place.uuid)">
          {{ place.name }}{{ place.town === "" ? "" : " - " + place.town }}
        </a>
      </div>
      <div
        v-bind:id="getCollapse(place.uuid, true)"
        class="panel-collapse collapse"
      >
        <div class="panel-body">
          <div
            class="week d-flex flex-row align-items-stretch justify-content-center"
          >
            <div class="p-2 d-flex flex-column">
              <label class="p-2"></label>
              <div
                class="list-complete-item flex-fill p-2"
                v-for="hours in getHours()"
                :key="hours.title"
              >
                <p class="title-hour">{{ hours.title }}</p>
              </div>
            </div>
            <div
              class="p-2 d-flex flex-column"
              v-for="day in (1, 7)"
              :key="day"
            >
              <label class="p-2">{{ days[day] }}</label>
              <div
                class="list-complete-item flex-fill p-2"
                v-for="disponibilite in getPlannings(day)"
                :key="disponibilite.uuid"
              >
                <check
                  v-bind:item="disponibilite"
                  v-on:onChange="onChangeDisponibilite"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="message-disponibilite">
          <span>Cochez vos disponibilités parmis les cases colorées.</span>
        </div>
        <transition name="fade">
          <div
            class="alert"
            v-bind:class="[isError ? 'alert-danger' : 'alert-success']"
            v-if="messageAlert"
          >
            {{ messageAlert }}
          </div>
        </transition>
        <button
          class="btn btn-success btn-md center-block button-vue"
          v-on:click="onSave"
          :disabled="isSave"
        >
          <icon
            class="wait"
            name="spinner"
            spin
            v-bind:class="[isSave ? '' : 'hide']"
          />
          Enregistrer
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import "vue-awesome/icons";
import Icon from "vue-awesome/components/Icon";
import HourUtils from "../utils/hourUtils";
import { uuid } from "vue-uuid";
import CreneauUtils from "../utils/creneauUtils";
import Check from "@/components/DisponibiliteCheckboxItem";

export default {
  name: "DisponibiliteItem",

  data() {
    return {
      days: ["", "Lun", "Mar", "Mer", "Jeu", "Ven", "Sam", "Dim"],
      place: {
        uuid: this.item.node.uuid,
        name: this.item.node.nom,
        town: this.item.node.ville,
        hour: this.item.node.hour,
        minute: this.item.node.minute,
        shift: this.item.node.shift,
        disponibilites: this.item.node.disponibilites.edges,
        plannings: this.item.node.plannings.edges,
      },
      creneauMax: null,
      disponibilites: [],
      isSave: false,
      isError: false,
      messageAlert: "",
    };
  },

  mounted: function () {
    var creneauUtils = new CreneauUtils(
      this.place.hour,
      this.place.minute,
      this.place.shift
    );

    this.creneauMax = creneauUtils.getHourMax;
  },

  props: {
    item: Object,
  },

  components: {
    Icon,
    Check,
  },

  methods: {
    getCollapse: function (index, isId = false) {
      let value = "#collapse" + index;

      if (isId) {
        value = "collapse" + index;
      }

      return value;
    },

    getHours: function () {
      let result = [];

      if (this.place.plannings.length !== 0) {
        for (let hour = 1; hour <= this.creneauMax; hour++) {
          const value = {
            title: this.getCreneau(hour) + " - " + this.getCreneau(hour, true),
          };

          result.push(value);
        }
      }

      return result;
    },

    getCreneau: function (hour, isEnd = false) {
      let hourUtils = new HourUtils(
        hour,
        this.place.hour,
        this.place.minute,
        this.place.shift,
        isEnd
      );

      return hourUtils.getTitle;
    },

    getPlannings: function (day) {
      let result = [];

      if (this.place.plannings.length !== 0) {
        let plannings = this.place.plannings.slice(0);
        let disponibilites = this.place.disponibilites.slice(0);

        // Filter by day
        plannings = plannings.filter((item) => item.node.day === day);
        disponibilites = disponibilites.filter((item) => item.node.day === day);

        for (let hour = 1; hour <= this.creneauMax; hour++) {
          let disponibilite = {
            uuid: uuid.v4(),
            isEnabled: false,
            isChecked: false,
            day: day,
            hour: hour,
          };

          // Contrôle si l'horaire est dans les plannings
          if (
            plannings.some((value) => {
              return value.node.hour === hour;
            })
          ) {
            // Contrôle si l'horaire est dans les disponibilites
            if (
              disponibilites.some((value) => {
                return value.node.hour === hour;
              })
            ) {
              disponibilite.isEnabled = true;
              disponibilite.isChecked = true;
              disponibilite.uuid = this.getDisponibiliteUuid(
                disponibilites,
                hour
              );
            } else {
              disponibilite.isEnabled = true;
            }
          }

          result.push(disponibilite);
        }
      }

      return result;
    },

    getDisponibiliteUuid: function (disponibilites, hour) {
      for (let disponibilite of disponibilites) {
        if (disponibilite.node.hour === hour) {
          return disponibilite.node.uuid;
        }
      }

      return "";
    },

    onChangeDisponibilite: function (disponibilite) {
      let indice = this.disponibilites
        .map(function (element) {
          return element.uuid;
        })
        .indexOf(disponibilite.uuid);

      if (indice !== -1) {
        this.disponibilites.splice(indice, 1);
      } else {
        this.disponibilites.push(disponibilite);
      }
    },

    onSave: function () {
      if (this.disponibilites.length !== 0) {
        this.isSave = true;
        this.$emit("onSave", this, this.place.uuid, this.disponibilites);
      }
    },

    clear: function () {
      this.disponibilites = [];
      this.isSave = false;
      this.messageAlert = "Enregistrement réussi.";

      setTimeout(() => {
        this.messageAlert = "";
      }, 3000);
    },

    error: function () {
      this.isSave = false;
      this.isError = true;
      this.messageAlert = "Erreur lors de l'enregistrement !";

      setTimeout(() => {
        this.isError = false;
        this.messageAlert = "";
      }, 3000);
    },
  },
};
</script>

<style lang="scss">
.styled-checkbox {
  position: absolute;
  opacity: 0;
  border-radius: 8px;

  & + label {
    position: relative;
    cursor: pointer;
    padding: 0;
  }

  // Box.
  & + label:before {
    content: "";
    display: inline-block;
    vertical-align: text-top;
    width: 1.05em;
    height: 1.05em;
    border-radius: 0.2em;
    background: #e89775;
  }

  // Disabled state label.
  &:disabled + label {
    color: #b8b8b8;
    cursor: not-allowed;
  }

  // Disabled box.
  &:disabled + label:before {
    background: #ddd;
    cursor: not-allowed;
    border-radius: 0.2em;
  }

  // Checkmark. Could be replaced with an image
  &:checked + label:after {
    content: "";
    position: absolute;
    top: calc((100% / 2) - 2px);
    left: calc((100% / 2) - 6px);
    background: #e89775;
    width: 2px;
    height: 2px;
    box-shadow: 2px 0 0 white, 4px 0 0 white, 4px -2px 0 white, 4px -4px 0 white,
      4px -6px 0 white, 4px -8px 0 white;
    transform: rotate(45deg);
  }
}
</style>
