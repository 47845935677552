<template>
  <div class="vue">
    <div class="container-vue container">
      <h1>Disponibilités</h1>
      <transition-group name="list-complete">
        <div
          class="list-complete-item"
          v-for="lieu in lieux"
          :key="lieu.node.uuid"
        >
          <item v-bind:item="lieu" v-on:onSave="onSaveDisponibilites" />
        </div>
      </transition-group>
      <infinite-loading @infinite="getPlanning" spinner="spiral">
        <template v-slot:no-more>
          <span></span>
        </template>
        <template v-slot:no-results>
          <div class="message">
            <h2>Pas de lieu.</h2>
          </div>
        </template>
        <template v-slot:error>
          <div class="message">
            <h2>Opps, something went wrong.</h2>
          </div>
        </template>
      </infinite-loading>
    </div>
  </div>
</template>

<script>
import { HTTP } from "../http-constants";
import Item from "@/components/DisponibiliteItem";
import Graphql from "../utils/graphQl";
import QueryUtils from "../utils/queries/disponibiliteQuery";
import RedirectMixin from "../utils/redirectionMixin";

export default {
  name: "Disponibilite",

  mixins: [RedirectMixin],

  title() {
    return `${this.title} - ${this.congregationName}`;
  },

  data() {
    return {
      lieux: [],
      cursorLieu: "",
      title: this.$store.state.title,
      token: this.$store.state.token,
      userUuid: this.$store.state.userUuid,
      congregationName: this.$store.state.congregationName,
      congregationUuid: this.$store.state.congregationUuid,
    };
  },

  components: {
    Item,
  },

  methods: {
    getPlanning: function ($state) {
      QueryUtils.setRead();
      QueryUtils.setVariables(this.getQueryVariables());

      // Start GraphQL - Return Lieu
      new Graphql(this.token, QueryUtils).request().then((response) => {
        let result = response.data.data.lieus.edges;

        if (result.length) {
          this.cursorLieu = result[result.length - 1].cursor;

          // Trie
          result = result.sort((a, b) => a.node.nom.localeCompare(b.node.nom));
          this.lieux.push(...result);

          $state.loaded();
        } else {
          $state.complete();
        }
      });
      // End GraphQL
    },

    onSaveDisponibilites: function (
      disponibiliteItem,
      placeUuid,
      disponibilites
    ) {
      let disponibiliteValues = [];

      // Get place disponiblities
      let indicePlace = this.lieux
        .map(function (element) {
          return element.node.uuid;
        })
        .indexOf(placeUuid);

      if (indicePlace !== -1) {
        let placeDisponibilites = this.lieux[indicePlace].node.disponibilites
          .edges;

        for (const disponibilite of disponibilites) {
          let indiceFound = placeDisponibilites
            .map(function (element) {
              return element.node.uuid;
            })
            .indexOf(disponibilite.uuid);

          if (indiceFound !== -1) {
            // Is Found "Disponibilite"
            if (!disponibilite.isChecked) {
              // Checkbox unchecked - remove disponibilite
              disponibiliteValues.push(
                this.getMutableVariables(disponibilite, "Delete")
              );

              // Remove in array lieu
              placeDisponibilites.splice(indiceFound, 1);
            }
          } else {
            // Not Found "Disponibilite"
            if (disponibilite.isChecked) {
              // Checkbox checked - add disponibilite
              disponibiliteValues.push(
                this.getMutableVariables(disponibilite, "Update")
              );

              // Add in array lieu
              placeDisponibilites.push({
                node: {
                  day: disponibilite.day,
                  hour: disponibilite.hour,
                  uuid: disponibilite.uuid,
                },
              });
            }
          }
        }

        this.lieux[indicePlace].node.disponibilites.edges = placeDisponibilites;
      }

      if (disponibiliteValues.length !== 0) {
        // Start Post new Disponibilites
        HTTP.post(
          "/disponibilite-clis",
          {
            proclamateur: this.userUuid,
            lieu: placeUuid,
            disponibilites: disponibiliteValues,
          },
          {
            headers: { "X-Auth-Token": this.token },
          }
        ).then((response) => {
          if (response.data.save) {
            disponibiliteItem.clear();
          } else {
            disponibiliteItem.error();
          }
        });
        // End Post
      }
    },

    getQueryVariables: function () {
      let variables = {
        uuid: this.userUuid,
        congregation: this.congregationUuid,
      };

      if (this.cursorLieu !== "") {
        variables["cursor"] = this.cursorLieu;
      }

      return variables;
    },

    getMutableVariables: function (disponibilite, action) {
      return {
        day: disponibilite.day,
        hour: disponibilite.hour,
        uuid: disponibilite.uuid,
        action: action,
      };
    },
  },
};
</script>
